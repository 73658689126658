import * as React from 'react'
  /* @jsx mdx */
import { mdx } from '@mdx-js/react';
/* @jsxRuntime classic */
/* @jsx mdx */
import DefaultLayout from "/opt/build/repo/src/components/postLayout.js";
import Seo from "../../components/seo";
export const _frontmatter = {};
const layoutProps = {
  _frontmatter
};
const MDXLayout = DefaultLayout;
export default function MDXContent({
  components,
  ...props
}) {
  return <MDXLayout {...layoutProps} {...props} components={components} mdxType="MDXLayout">



    <Seo title="New York guide | Atharva Patil" mdxType="Seo" />
    <h2>{`New York Recos for Rahul`}</h2>
    <h4>{`Chelsea photowalk and views`}</h4>
    <p>{`Perfect area for modern NYC photowalk. Upscale area with with some really cool modern architecture. Keep an lookout for a lot of triangle patterns (My consipiracy theory: NYC is the HQ of illuminati and are sending secrect messages in the architecture).`}</p>
    <h5>{`Some things to do:`}</h5>
    <ul>
      <li parentName="ul">{`Walk on the `}<a parentName="li" {...{
          "href": "https://maps.app.goo.gl/V98JPWWVX2v3xReh7"
        }}>{`High Line`}</a>{`. It's a perfect place to do a photowalk. `}</li>
      <li parentName="ul">{`Take views of the city from `}<a parentName="li" {...{
          "href": "https://maps.app.goo.gl/hN2AuQE4LbsHtc8z8"
        }}>{`the Edge`}</a>{` (preferrably around sunset)`}</li>
      <li parentName="ul">{`The Vessel, cool architecture. Right in front of the Edge and at the end of the High Line.`}</li>
      <li parentName="ul"><a parentName="li" {...{
          "href": "https://maps.app.goo.gl/NHDdfeQkArZHnNS1A"
        }}>{`Whitney Museum of Art`}</a>{`. Don't miss the rooftop. Again amazing view to enjoy a beer with. `}</li>
      <li parentName="ul"><a parentName="li" {...{
          "href": "https://maps.app.goo.gl/BEEZVkxNAgUvjafS8"
        }}>{`Little Island`}</a>{`. Man made park in the Hudson river.`}</li>
    </ul>
    <hr></hr>
    <h4>{`Pizzaz`}</h4>
    <p>{`NYC without Pizza recos means the person lives in New Jersey and calls themselves a New Yorker for Instagram clout.`}</p>
    <h5>{`My favs`}</h5>
    <ul>
      <li parentName="ul"><a parentName="li" {...{
          "href": "https://maps.app.goo.gl/EW4cMFUH66DCvpHK7"
        }}>{`Joe's on Carmine`}</a>{`. Spiderman delivered this in the movies`}</li>
      <li parentName="ul"><a parentName="li" {...{
          "href": "https://maps.app.goo.gl/mQzm6kiVV6ErcF267"
        }}>{`Song' E Napule`}</a>{`. Sit down place. Amazing pizza`}</li>
      <li parentName="ul"><a parentName="li" {...{
          "href": "https://maps.app.goo.gl/6zUPF6BtJDbvZH9F6"
        }}>{`Prince St Pizza`}</a></li>
      <li parentName="ul"><a parentName="li" {...{
          "href": "https://maps.app.goo.gl/93mF6Sg48SvTCQdJ8"
        }}>{`Grimaldi's Pizzeria`}</a>{`. Sit down place with a queue. Would recommened going with company since they sell the whole pie. Also, amazing view of Brooklyn Bridge`}</li>
      <li parentName="ul"><a parentName="li" {...{
          "href": "https://maps.app.goo.gl/C9tjocrpFrR3hMDz9"
        }}>{`Lucali`}</a></li>
    </ul>
    <hr></hr>
    <h4>{`Man-made beauty`}</h4>
    <ul>
      <li parentName="ul"><a parentName="li" {...{
          "href": "https://maps.app.goo.gl/nR2kVpmnyKgC6BYR6"
        }}>{`New York Public Library`}</a></li>
      <li parentName="ul"><a parentName="li" {...{
          "href": "https://maps.app.goo.gl/1trHbvczwyw6r2169"
        }}>{`Bethesda Terrace`}</a></li>
      <li parentName="ul"><a parentName="li" {...{
          "href": "https://maps.app.goo.gl/m1B8BCmtYNnSPSXn6"
        }}>{`The Metropolitan Museum of Art`}</a></li>
      <li parentName="ul"><a parentName="li" {...{
          "href": "https://maps.app.goo.gl/8iv68MXd5MPF9sgr8"
        }}>{`Flatiron Building`}</a></li>
      <li parentName="ul"><a parentName="li" {...{
          "href": "https://maps.app.goo.gl/hmjKv1D9LfeYDKoE8"
        }}>{`Rockefeller Center`}</a></li>
      <li parentName="ul"><a parentName="li" {...{
          "href": "https://maps.app.goo.gl/w9YQ1J8KMF6A7qYf7"
        }}>{`St. Patrick's Cathedral`}</a></li>
      <li parentName="ul"><a parentName="li" {...{
          "href": "https://maps.app.goo.gl/bKSrkaGVQM4n6hbQ8"
        }}>{`One World Trade Center + 911 memorial`}</a></li>
      <li parentName="ul"><a parentName="li" {...{
          "href": "https://maps.app.goo.gl/VwPEkYaXEWkbeGrAA"
        }}>{`Brooklyn Bridge`}</a></li>
      <li parentName="ul"><a parentName="li" {...{
          "href": "https://maps.app.goo.gl/uNcggwNF2WVbgrRw8"
        }}>{`Charging Bull`}</a></li>
    </ul>
    <hr></hr>
    <h4>{`Experiences`}</h4>
    <ul>
      <li parentName="ul"><a parentName="li" {...{
          "href": "https://maps.app.goo.gl/7MN6z4oYD65aS3YV7"
        }}>{`Fotografiska Museum NYC`}</a></li>
      <li parentName="ul"><a parentName="li" {...{
          "href": "https://maps.app.goo.gl/j2gFiDK1phxGUWRM7"
        }}>{`Smalls Jazz Club`}</a></li>
      <li parentName="ul"><a parentName="li" {...{
          "href": "https://maps.app.goo.gl/1BuiGT3K1mkefk8H7"
        }}>{`Comedy Cellar`}</a></li>
      <li parentName="ul">{`Broadway shows`}</li>
      <li parentName="ul"><a parentName="li" {...{
          "href": "https://maps.app.goo.gl/PteM3pWAaPQNovn76"
        }}>{`Union Square Park`}</a>{` weekend market`}</li>
      <li parentName="ul">{`Walk the Brooklyn Bridge`}</li>
      <li parentName="ul">{`Go to a `}<a parentName="li" {...{
          "href": "https://en.wikipedia.org/wiki/Dive_bar"
        }}>{`dive bar`}</a>{`, NYC has a lot of them`}</li>
    </ul>
    <hr></hr>
    <h4>{`Speakeasy's`}</h4>
    <ul>
      <li parentName="ul"><a parentName="li" {...{
          "href": "https://maps.app.goo.gl/WHtfDKAQNBoa3dTg8"
        }}>{`Garfunkel's`}</a></li>
      <li parentName="ul"><a parentName="li" {...{
          "href": "https://maps.app.goo.gl/s3vHSfJpmY6zkUvN9"
        }}>{`Employees Only`}</a></li>
      <li parentName="ul"><a parentName="li" {...{
          "href": "https://maps.app.goo.gl/Mt63k4H4hGehizZm6"
        }}>{`Patent Pending`}</a></li>
    </ul>

    </MDXLayout>;
}
;
MDXContent.isMDXComponent = true;
      